import { useDispatch, useSelector } from 'react-redux';
import { getScheduledTour } from 'app/shared/utils/listingUtils';
import { TOUR_TYPES } from './constants';
import { useCallback } from 'react';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import type { RootReduxState } from 'app/shared/flux/reducers';

interface InstantTourFormUpdates {
  isOpen?: boolean;
  step?: string;
  tourType?: string;
  selectedDate?: string;
  selectedDateTime?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export const useInstantTourForm = () => {
  const dispatch = useDispatch();

  const updateInstantTourForm = useCallback(
    (updates: InstantTourFormUpdates) => {
      dispatch(ListingEngineActions.hpTours.updateInstantTourForm(updates));
    },
    [dispatch],
  );

  const fetchTourData = useCallback(
    async (aliasEncoded: string, tourType: string) => {
      await dispatch(ListingEngineActions.hpTours.fetchAvailableToursForListing(aliasEncoded, tourType));
    },
    [dispatch],
  );

  return {
    fetchTourData,
    updateInstantTourForm,
  };
};

export const useSelectedTourProvider = () => {
  const instantTourForm = useSelector((state: RootReduxState) => state.currentListingDetails.instantTourForm);
  const { instantTourProviders } = useSelector(
    (state: RootReduxState) => state.currentListingDetails.currentListing!.details,
  );

  const selectedTourType = instantTourForm?.tourType;

  if (selectedTourType === TOUR_TYPES.IN_PERSON) {
    return instantTourProviders?.inPersonProvider ?? {};
  } else if (selectedTourType === TOUR_TYPES.LIVE_VIDEO) {
    return instantTourProviders?.liveVideoProvider ?? {};
  } else if (selectedTourType === TOUR_TYPES.SELF_GUIDED) {
    return instantTourProviders?.selfGuidedProvider ?? {};
  }

  return {};
};

export const useScheduledTourProvider = () => {
  const { aliasEncoded, details } = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing!);
  const scheduledToursForUser = useSelector((state: RootReduxState) => state.user.scheduledTours);
  const { tourType: scheduledTourType } = getScheduledTour(aliasEncoded, scheduledToursForUser);
  const { instantTourProviders } = details;

  if (scheduledTourType === TOUR_TYPES.IN_PERSON) {
    return instantTourProviders?.inPersonProvider ?? {};
  } else if (scheduledTourType === TOUR_TYPES.LIVE_VIDEO) {
    return instantTourProviders?.liveVideoProvider ?? {};
  } else if (scheduledTourType === TOUR_TYPES.SELF_GUIDED) {
    return instantTourProviders?.selfGuidedProvider ?? {};
  }

  return {};
};
