// @ts-nocheck
/* eslint-enable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Actions
import AppActions from 'app/shared/flux/actions/AppActions';
import ContactActions from 'app/shared/flux/actions/ContactActions';
import ConversationActions from 'app/shared/flux/actions/ConversationActions';
import ErrorActions from 'app/shared/flux/actions/ErrorActions';
import NotificationActions from 'app/shared/flux/actions/NotificationActions';
import UserActions from 'app/shared/flux/actions/UserActions';
import UserSearchActions from 'app/shared/flux/actions/UserSearchActions';

// Components
import ActionPopupWrapper from 'app/shared/modules/popups/ActionPopupWrapper';
import ChatButton from 'app/shared/modules/hdp/ChatButton';
import ContactForm from 'app/shared/modules/hdp/form/Contact/ContactForm';
import ContactListedBy from 'app/shared/modules/hdp/form/Contact/ContactListedBy';
import ContactOverlay from 'app/shared/modules/hdp/form/Contact/ContactOverlay';
import Disclaimer from 'app/shared/modules/hdp/form/Disclaimer';
import SatelliteMsgStatus from 'app/shared/modules/satellite/SatelliteMsgStatus';
import LandlordInfo from 'app/shared/modules/hdp/form/Contact/LandlordInfo';
import WaitlistSuccessPopup from 'app/shared/modules/hdp/form/WaitlistSuccessPopup';

// Misc / Utils
import 'app/shared/modules/hdp/form/Contact/Contact.scss';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import { trackForRentContact } from 'app/client/tracking';
import contactUtils from 'app/shared/utils/contactUtils';
import controller from './controller';
import loginUtils from 'app/shared/utils/loginUtils';
import numberUtils from 'app/shared/utils/numberUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import {
  TrackInlineContactSubmit,
  TrackModalContactFormView,
  TrackModalContactSubmit,
} from 'app/shared/models/Clickstream/HdpClickstreamEvents';
import { getListingType, getTrackingFunction } from 'app/shared/utils/clickstreamUtils';
import constants from 'app/shared/constants/ConstantsBundle';

class Contact extends Component {
  static propTypes = {
    className: PropTypes.string,
    clientLoaded: PropTypes.bool.isRequired,
    closePopup: PropTypes.func,
    conversations: PropTypes.object,
    countryCode: PropTypes.string,
    currentListing: PropTypes.object,
    customForm: PropTypes.func,
    customTracking: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    hasSentContact: PropTypes.bool,
    hasShownPostContactSavedSearch: PropTypes.bool,
    hdpV3Form: PropTypes.bool,
    isListingAgentPositionExperiment: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isMobile: PropTypes.bool,
    isPopup: PropTypes.bool,
    listing: PropTypes.object,
    onHidePopup: PropTypes.func,
    onShowSuccessModal: PropTypes.func,
    pathname: PropTypes.string,
    shouldUseAreaBoundary: PropTypes.bool,
    similarListings: PropTypes.array,
    unitDetails: PropTypes.object,
    user: PropTypes.object,
    userInquiryDataCache: PropTypes.object,
  };

  static defaultProps = {
    isPopup: false,
    listing: {
      contact: {},
      userItemTypes: [],
    },
    onHidePopup: () => {},
    onShowSuccessModal: () => {},
    similarListings: [],
    unitDetails: {},
    user: {
      info: {},
    },
    userInquiryDataCache: {},
  };

  constructor(props) {
    super(props);
    const { listing } = this.props;
    const { userOnWaitlist } = listing;

    this.state = {
      activeModal: null,
      contactSubmitted: false,
      emailErrorMessage: '',
      errorMessage: '',
      isLoading: false,
      isSuccessPopupVisible: false,
      phoneErrorMessage: '',
      previousInquiryMessagePopup: includes(listing.userItemTypes, 'inquiry') || userOnWaitlist,
      nestedVisiblePopup: null,
    };
    this.shouldDisplayPostContactSavedSearch = false;
  }

  componentDidMount() {
    const { dispatch, userInquiryDataCache, shouldUseAreaBoundary, pathname, listing } = this.props;
    const { previousInquiryMessagePopup } = this.state;
    const { userOnWaitlist } = listing;
    const useMapBounds = routeUtils.isAreaUrl(pathname) || routeUtils.isPadOrBuildingUrl(pathname);

    const nextInquiryMessagePopup = includes(listing.userItemTypes, 'inquiry') || userOnWaitlist;
    if (previousInquiryMessagePopup !== nextInquiryMessagePopup) {
      this.setState({
        previousInquiryMessagePopup: nextInquiryMessagePopup,
      });
    }

    if (userInquiryDataCache.isPending) {
      dispatch(UserActions.inquiry.getMessageCache());
    }

    dispatch(UserSearchActions.search.saved.displaySearchPromptInquiry({ shouldUseAreaBoundary, useMapBounds }))
      .then((res) => {
        const { data } = res;
        this.shouldDisplayPostContactSavedSearch = Boolean(data);
        return data;
      })
      .catch((error) => {
        dispatch(
          ErrorActions.errorHandler({
            error,
            errorLocation: 'component.contact.componentDidMount',
            errorClass: 'userSearchActions',
          }),
        );
      });

    if (this.props.isPopup) {
      dispatch(analyticsEvent({ newLaneEvent: TrackModalContactFormView() }));
    }
  }

  handleSetActiveModal = (modal = null) => {
    this.setState({ activeModal: modal });
  };

  handlePhoneClick = () => {
    const { currentListing, dispatch, isPopup } = this.props;

    let newLaneEvent;
    const listingType = getListingType(currentListing);

    if (isPopup) {
      newLaneEvent = getTrackingFunction({ type: listingType })({
        triggerObject: 'home_details_component|modal_contact_form',
        triggerSource: 'button_to_complete_contact_form',
      });
    } else {
      newLaneEvent = getTrackingFunction({ type: listingType })({
        triggerObject: 'home_details_component|inline_form',
        triggerSource: 'button_to_complete_contact_form',
      });
    }

    dispatch(
      analyticsEvent(gaEvents.LEAD_PHONE, {
        label: listingType,
        newLaneEvent,
      }),
    );
  };

  handleTrackSubmitClick = (rentalSubmitId: string) => {
    const { currentListing, customTracking, dispatch, hdpV3Form, isPopup } = this.props;
    let newLaneEvent;
    const listingType = getListingType(currentListing);

    if (customTracking) {
      customTracking();
    } else if (isPopup && !customTracking) {
      dispatch(
        analyticsEvent(gaEvents.CONTACT_FORM_MODAL, {
          newLaneEvent: TrackModalContactSubmit({ rentalSubmitId }),
        }),
      );
    } else if (hdpV3Form && !isPopup && !customTracking) {
      dispatch(analyticsEvent(gaEvents.CONTACT_FORM_FLOATING));
    } else {
      dispatch(
        analyticsEvent(gaEvents.CONTACT_FORM_INLINE, {
          newLaneEvent: TrackInlineContactSubmit({ rentalSubmitId }),
        }),
      );
    }

    if (isPopup) {
      newLaneEvent = getTrackingFunction({ type: listingType })({
        triggerObject: 'home_details_component|modal_contact_form',
        triggerSource: 'button_to_complete_contact_form',
      });
    } else {
      newLaneEvent = getTrackingFunction({ type: listingType })({
        triggerObject: 'home_details_component|inline_form',
        triggerSource: 'button_to_complete_contact_form',
      });
    }

    dispatch(
      analyticsEvent(gaEvents.LEAD_EMAIL, {
        label: listingType,
        newLaneEvent,
      }),
    );
  };

  handleFormSubmitSuccess = (submittedValues = {}) => {
    const { closePopup, currentListing, dispatch, isPopup, hdpV3Form, hasShownPostContactSavedSearch, isLoggedIn } =
      this.props;

    // NOTE: Disabled for now as there is no corresponding GA event and this messes up validation testing.
    // Fire additional event specifically for all contact form types:
    // dispatch(
    //     analyticsEvent({
    //         newLaneEvent: mapTrackingFunctions[listingPaid]({ triggerObject: 'home_details_component|contact_form' })
    //     })
    // );

    // Fire tracking event if an inquiry was submitted successfully and the
    // `requestToApply` checkbox was marked
    if (submittedValues.requestToApply) {
      dispatch(analyticsEvent(gaEvents.HDP_CONTACT_CTA_APPLICATION_REQUEST_SUCCESS));
    }

    this.setState(
      {
        isLoading: false,
        isSuccessPopupVisible: true,
        contactSubmitted: true,
      },
      () => {
        if (!isPopup && !hdpV3Form) {
          // handle DOM scrolling
          const sidebar = document.getElementById('SplitMapTemplate-right-sidebar');
          const hdpFormWrapper = document.getElementById('HdpFormWrapper');

          if (sidebar) {
            sidebar.scrollTop = hdpFormWrapper.offsetTop;
          } else if (document) {
            document.scrollTop = hdpFormWrapper.offsetTop;
          }
        }
      },
    );

    // If we're using PopupModal and need to close the modal after an action occurs...
    // ...In this case, after submitting a form successfully.
    if (closePopup) {
      closePopup();
    }

    if (this.shouldDisplayPostContactSavedSearch && isLoggedIn && !hasShownPostContactSavedSearch) {
      // if Rachel is logged in, show post contact saved search modal once per session if api returns true
      this.handleNestedVisiblePopupChange('postContactSavedSearch');
    } else if (currentListing.waitlisted) {
      this.handleNestedVisiblePopupChange('waitlistSuccess');
    } else {
      this.handleNestedVisiblePopupChange('contactSuccess');
    }
  };

  handleNestedVisiblePopupChange = (nestedPopupName) => {
    this.setState({ nestedVisiblePopup: nestedPopupName });
  };

  handleFormSubmitFailure = (response = {}) => {
    this.setState({
      isLoading: false,
      errorMessage: response.message || 'Failed to send inquiry.',
    });
    logger.error(response, 'Form submit failure');
  };

  getInquiryParams = (inputValues) => {
    const { listing, unitDetails } = this.props;
    const { trusted, aliasEncoded } = listing;
    const { demoted } = listing.details;
    const listingPaid = Boolean(trusted);
    const listingDemoted = Boolean(demoted);

    return {
      alias: aliasEncoded,
      displayName: listing.displayName,
      address: listing.address.street,
      baths: inputValues.numBaths,
      beds: inputValues.numBeds,
      email: inputValues.userEmail,
      phone: numberUtils.formatPhoneNumber(inputValues.userPhoneNumber),
      name: inputValues.userName,
      text: contactUtils.getInquiryText({
        userMessage: inputValues.userMessage,
        lowIncomeMessage: inputValues.lowIncomeMessage,
        listing,
      }),
      textToCache: inputValues.userMessage,
      emailUser: true,
      defaultMessage: listing.contact.defaultInquiryMessage,
      paid: listingPaid,
      demoted: listingDemoted,
      modelId: unitDetails.modelId ? unitDetails.modelId : null,
      requestToApply: inputValues.requestToApply,
    };
  };

  validateUserInfo = async (inputValues) => {
    const { dispatch } = this.props;
    const { aliasEncoded: listingAlias } = this.props.listing;
    const email = inputValues.userEmail;
    const phone = inputValues.userPhoneNumber;
    const params = { listingAlias, email, phone };

    // Reset state before proceeding
    this.setState({ emailErrorMessage: '', phoneErrorMessage: '', isLoading: true });

    // Call validation endpoint
    try {
      const res = await dispatch(UserActions.inquiry.validateUserContactInfo(params));
      const data = res.data;
      const emailData = data.email;
      const phoneData = data.phone;

      // If errors are detected, let the UI know
      if (!emailData.valid) {
        this.setState({
          emailErrorMessage: 'Invalid email address',
          isLoading: false,
        });
      }

      if (!phoneData.valid) {
        this.setState({
          phoneErrorMessage: 'Invalid phone number',
          isLoading: false,
        });
      }

      return { isValid: emailData.valid && phoneData.valid };
    } catch (error) {
      logger.error(error, 'Phone and/or email validation error');
      this.setState({
        errorMessage: 'We could not validate your contact information. Please try again.',
        isLoading: false,
      });
      return { isValid: false };
    }
  };

  /**
   * Continues submit contact inquiry flow after user closes Renter Profile popup modal
   */
  continueContactInquiryFlow = async () => {
    const { listing, dispatch } = this.props;
    const { trusted, aliasEncoded } = listing;
    const { inquiryParams, rentalSubmitId } = contactUtils.getDelayedContactInquiry();

    try {
      await dispatch(
        ContactActions.submitContact({
          listing,
          inquiryParams,
          rentalSubmitId,
        }),
      );

      trackForRentContact(listing);

      this.handleFormSubmitSuccess(inquiryParams);

      if (!trusted) {
        dispatch(ConversationActions.optimisticSetSentMessage(aliasEncoded));
      }
    } catch (error) {
      dispatch(
        ErrorActions.errorHandler({
          error,
          errorLocation: 'component.contact.handleSubmitForm',
          errorClass: 'contactActions',
        }),
      );

      this.handleFormSubmitFailure();
    }
  };

  handleSubmitForm = async (inputValues) => {
    const { listing, dispatch, user, countryCode } = this.props;
    /**
     * DISABLE CONTACT INFO VALIDATION
     * Turns off contact info validation when developing. This should speed
     * things up when devs need to work on CTA related logic.
     *
     * __DEV__ flag should ensure that this never gets enabled in production environments.
     */
    const DISABLE_VALIDATION_FOR_CONTACT_TESTING = true;
    const { isValid } =
      DISABLE_VALIDATION_FOR_CONTACT_TESTING && __DEV__ ? { isValid: true } : await this.validateUserInfo(inputValues);

    if (isValid) {
      try {
        const rentalSubmitId = uuidv4();

        this.handleTrackSubmitClick(rentalSubmitId);
        this.setState({
          isLoading: true,
          emailErrorMessage: '',
          errorMessage: '',
        });

        await dispatch(AppActions.setAppStoreBool('userExists', false)); // ?? what does this do?
        const userDetails = await dispatch(ContactActions.loginUser(user.loggedIn, inputValues.userEmail));

        if (!userDetails) {
          this.handleFormSubmitFailure();
          return;
        }

        if (userDetails.error) {
          this.handleFormSubmitFailure(userDetails);
          return;
        }

        if (userDetails.status === 'ACCOUNT_DEACTIVATED') {
          this.setState({
            isLoading: false,
            emailErrorMessage:
              'This email is associated with an account marked for deletion. Please use another email address.',
          });

          return;
        }

        if (userDetails.status === 'USER_ALREADY_EXISTS') {
          dispatch(AppActions.setAppStoreBool('userExists', true));
        } else if (
          userDetails.success &&
          userDetails?.data?.passwordStatus?.toLowerCase() === 'freepass' &&
          loginUtils.isCanadianUser(countryCode, inputValues.userEmail)
        ) {
          // freepassed user from canada
          dispatch(NotificationActions.email.updateEmailOptIn(true, 'inquiry'));
        }

        // Store contact inquiry details for sending after user closes rental profile form.
        contactUtils.setDelayedContactInquiry({
          listing,
          inquiryParams: this.getInquiryParams(inputValues),
          rentalSubmitId,
        });

        return this.handleNestedVisiblePopupChange('postContactRenterProfile');
      } catch (error) {
        dispatch(
          ErrorActions.errorHandler({
            error,
            errorLocation: 'component.contact.handleSubmitForm',
            errorClass: 'contactActions',
          }),
        );

        this.handleFormSubmitFailure();
      }
    }
  };

  handleNestedPopupClose = () => {
    if (this.state.nestedVisiblePopup === 'postContactRenterProfile') {
      this.handleNestedVisiblePopupChange(null);
      this.continueContactInquiryFlow();
      return;
    }

    const { onHidePopup } = this.props;

    // Close nested popup modal
    this.handleNestedVisiblePopupChange(null);

    // Proceed with original close logic
    onHidePopup();
  };

  renderModal = () => {
    const { isPopup } = this.props;
    const { activeModal } = this.state;

    if (!activeModal) {
      return null;
    }

    return (
      <div
        className={cx('HdpForm-Contact-modal', {
          'HdpForm-Contact-modal-popup': isPopup,
        })}
      >
        {activeModal === 'landlordInfo' && (
          <LandlordInfo
            isPopup={isPopup}
            onClickPhoneContact={this.handlePhoneClick}
            onCloseModal={this.handleSetActiveModal}
          />
        )}
      </div>
    );
  };

  render() {
    const {
      className,
      clientLoaded,
      conversations,
      hasSentContact,
      isListingAgentPositionExperiment,
      isMobile,
      isPopup,
      hdpV3Form,
      listing,
      onHidePopup,
      similarListings,
      unitDetails,
      user,
      userInquiryDataCache,
    } = this.props;

    const {
      contactSubmitted,
      emailErrorMessage,
      errorMessage,
      isLoading,
      isSuccessPopupVisible,
      phoneErrorMessage,
      previousInquiryMessagePopup,
      nestedVisiblePopup,
    } = this.state;

    const {
      address,
      aliasEncoded,
      maloneLotIdEncoded,
      trusted,
      contact,
      listedBy,
      details,
      isApartmentBldg,
      waitlisted,
    } = listing;

    const css = cx(className, {
      'HdpForm-Contact': !hdpV3Form,
      'HdpForm-Contact-popup': isPopup,
    });
    const CustomForm = this.props.customForm || null;
    if (isSuccessPopupVisible && !previousInquiryMessagePopup && waitlisted) {
      return (
        <div className={css}>
          <WaitlistSuccessPopup
            onHide={() => {
              if (isPopup) {
                onHidePopup();
              }

              this.setState({
                isSuccessPopupVisible: false,
              });
            }}
            similarListings={similarListings}
          />
        </div>
      );
    }

    if (isEmpty(contact)) {
      return null;
    }

    const hasConversation = conversations[aliasEncoded];
    const satelliteEnabled = hasConversation ? conversations[aliasEncoded].satelliteEnabled : false;

    return (
      <div className={css}>
        <div
          className={cx(
            { 'HdpForm-Contact-content-wrapper': !hdpV3Form },
            {
              'HdpForm-Contact-content-wrapper-inline': !isPopup && !hdpV3Form,
            },
          )}
        >
          {contact.displayContactBox ? (
            <div
              className={cx(
                { 'HdpForm-Contact-content': !hdpV3Form },
                {
                  'HdpForm-Contact-content-popup': isPopup,
                },
              )}
            >
              <ContactOverlay
                overlayOffset={hdpV3Form ? '61px' : null}
                contactSubmitted={contactSubmitted}
                hasConversation={hasConversation}
                previousInquiryMessagePopup={previousInquiryMessagePopup}
                onHideOverlay={this.handleOverlay}
                satelliteEnabled={satelliteEnabled}
                listing={listing}
                shouldLockFocus={isPopup}
              />
              {!hdpV3Form && !isListingAgentPositionExperiment && (
                <ContactListedBy
                  address={address}
                  details={details}
                  isApartmentBldg={isApartmentBldg}
                  isPopup={isPopup}
                  listedBy={listedBy}
                  maloneLotIdEncoded={maloneLotIdEncoded}
                  onSetActiveModal={this.handleSetActiveModal}
                  onClickPhoneContact={this.handlePhoneClick}
                  trusted={trusted}
                  waitlisted={waitlisted}
                />
              )}
              <ChatButton listing={listing} showCopy inContactBox />
              {hasConversation && (
                <SatelliteMsgStatus
                  className={cx('SatelliteMsgStatus-inContactBox', {
                    'SatelliteMsgStatus-inContactBoxPopup': isPopup,
                  })}
                />
              )}
              {(!hasConversation || !satelliteEnabled) && CustomForm && (
                <CustomForm
                  clientLoaded={clientLoaded}
                  contactSubmitted={contactSubmitted}
                  emailErrorMessage={emailErrorMessage}
                  errorMessage={errorMessage}
                  isLoading={isLoading}
                  isMobile={isMobile}
                  isPopup={isPopup}
                  listing={listing}
                  onSubmitForm={this.handleSubmitForm}
                  phoneErrorMessage={phoneErrorMessage}
                  user={user}
                  userInquiryDataCache={userInquiryDataCache}
                />
              )}

              {(!hasConversation || !satelliteEnabled) && !CustomForm && (
                <ContactForm
                  isCachedDataPending={userInquiryDataCache.isPending}
                  isExperiment={isListingAgentPositionExperiment}
                  clientLoaded={clientLoaded}
                  contactSubmitted={contactSubmitted}
                  emailErrorMessage={emailErrorMessage}
                  errorMessage={errorMessage}
                  isLoading={isLoading}
                  isMobile={isMobile}
                  isPopup={isPopup}
                  listing={listing}
                  onSetActiveModal={this.handleSetActiveModal}
                  onSubmitForm={this.handleSubmitForm}
                  phoneErrorMessage={phoneErrorMessage}
                  user={user}
                  userInquiryDataCache={userInquiryDataCache}
                  {...unitDetails}
                />
              )}
              {this.renderModal()}
            </div>
          ) : (
            <div className="HdpForm-Contact-content">
              <ContactListedBy
                address={address}
                details={details}
                isApartmentBldg={isApartmentBldg}
                isPopup={isPopup}
                listedBy={listedBy}
                maloneLotIdEncoded={maloneLotIdEncoded}
                onSetActiveModal={this.handleSetActiveModal}
                trusted={trusted}
                waitlisted={waitlisted}
              />
              This property did not provide a contact email address.
              {this.renderModal()}
            </div>
          )}
        </div>
        {!hasSentContact && (
          <div
            className={cx('HdpForm-Contact-disclaimer', {
              'HdpForm-Contact-disclaimer-popup': isPopup,
            })}
          >
            <Disclaimer linkType={isPopup ? 'primary' : 'inverted'} />
          </div>
        )}
        {nestedVisiblePopup && (
          <ActionPopupWrapper componentName={nestedVisiblePopup} onHidePopup={this.handleNestedPopupClose} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    area: state.area.area,
    clientLoaded: state.app.clientLoaded,
    conversations: state.user.conversations,
    countryCode: state.geolocation.area.countryCode,
    currentListing: state.currentListingDetails.currentListing,
    hasSentContact: controller.hasSentContact(state.user.inquiryDataCache),
    hasShownPostContactSavedSearch: state.app.hasShownPostContactSavedSearch,
    isListingAgentPositionExperiment:
      state?.analytics?.runningExperiments[constants.HOTPADS_WEB_LISTING_AGENT_POSITION]?.isControl === false || false,
    isLoggedIn: state.user.loggedIn,
    isMobile: state.app.device.screenWidth === 'sm',
    pathname: ownProps.location.pathname,
    shouldUseAreaBoundary: queryUtils.parse(ownProps.location.search).border !== false,
    user: state.user,
    userInquiryDataCache: state.user.inquiryDataCache,
  };
};

export { Contact }; // destructured export for test
export default withRouter(connect(mapStateToProps)(Contact));
