import type { ListingDetails } from 'app/types/listingDetails.type';
import {
  TrackDemotedListingLead,
  TrackFreeListingLead,
  TrackPaidListingLead,
} from '../models/Clickstream/HdpClickstreamEvents';

type ListingType = 'free' | 'paid' | 'paidDemoted';

export const getListingType = (listing: ListingDetails) => {
  let listingType = 'free';
  const { details, isPaidAndNotDemoted } = listing;
  const { demoted } = details;

  if (demoted) {
    listingType = 'paidDemoted';
  } else if (isPaidAndNotDemoted) {
    listingType = 'paid';
  }

  return listingType;
};

export const getTrackingFunction = ({ type }: { type: ListingType }) => {
  const mapTrackingFunctions = {
    free: TrackFreeListingLead,
    paid: TrackPaidListingLead,
    paidDemoted: TrackDemotedListingLead,
  };

  return mapTrackingFunctions[type];
};
