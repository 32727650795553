// @ts-nocheck
/* eslint-enable */
import ReactDOM from 'react-dom';
import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { inline, margin, padding, stacked } from 'app/shared/styles/_spacing';
import { colors } from 'app/shared/styles/_colors';
import { connect } from 'react-redux';
import assign from 'lodash/assign';
import mailcheck from 'mailcheck';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Components
import BedsBathsSelect from 'app/shared/modules/hdp/form/Contact/BedsBathsSelect';
import Button from 'app/shared/core/Button';
import Checkbox from 'app/shared/core/Checkbox';
import ContactListingRestrictions from 'app/shared/modules/hdp/form/Contact/ContactListingRestrictions';
import ContactUserInfo from 'app/shared/modules/hdp/form/Contact/ContactUserInfo';
import Input from 'app/shared/core/Input';
import InputPhoneNumber from 'app/shared/modules/InputPhoneNumber';
import Linker from 'app/shared/modules/Linker';
import MoveInCalendar from 'app/shared/modules/MoveInCalendar';
import Row from 'app/shared/core/Row';
import Text from 'app/shared/core/Text';

// Misc / Utils
import './ContactForm.scss';
import { shouldShowRequestToApplyButton } from 'app/shared/utils/cometUtils';
import controller from './controller';
import dateUtils from 'app/shared/utils/dateUtils';
import IconMoreInfo from 'images/icons/more-info-grey.svg';
import IconMoreInfoActive from 'images/icons/more-info-blue.svg';
import { listingUtils_getContactEmail } from 'app/shared/utils/listingUtils';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import ContactListedByV2 from './ContactListedByV2';

const { bool, object, func, string, number } = PropTypes;
const UPDATE_EMAIL_ERROR_MSG = 'Please update your email address.';

const ReadyToApplyText = styled(Text)`
  ${inline._1x};
  color: ${colors['$hpx-grey-600']};
  font-weight: bold;
`;
const StyledSubmitContainer = styled.div`
  ${stacked._1x};
  ${margin.top._3x};
`;
const StyledCalendarContainer = styled.div`
  ${stacked._2x};
`;
const StyledContactListingRestrictions = styled(ContactListingRestrictions)`
  ${(props) =>
    props &&
    props.isPopup === false &&
    css`
      ${padding.top._2x};
    `}
`;
const StyledEmailInput = styled(Input)`
  ${padding.top._2x};
`;
const StyledInputPhoneNumber = styled(InputPhoneNumber)`
  ${padding.top._2x};
`;
const StyledCheckbox = styled(Checkbox)`
  color: ${colors['$hpx-blue-600']};
`;
const StyledContactFormTipText = styled.div`
  color: ${colors['$hpx-grey-600']};
`;
const StyledAlertDiv = styled.div`
  color: ${colors['$hpx-red-400']};
`;
class ContactForm extends Component {
  static propTypes = {
    clientLoaded: bool.isRequired,
    ctaButtonContext: string,
    contactSubmitted: bool.isRequired,
    dispatch: func.isRequired,
    emailErrorMessage: string,
    errorMessage: string.isRequired,
    handleSetActiveModal: func,
    isCachedDataPending: bool.isRequired,
    isExperiment: bool,
    isPopup: bool.isRequired,
    isLoading: bool.isRequired,
    isMobile: bool.isRequired,
    listing: object.isRequired,
    onSetActiveModal: func,
    onSubmitForm: func.isRequired,
    phoneErrorMessage: string,
    beds: number,
    baths: number,
    user: object.isRequired,
    userInquiryDataCache: object.isRequired,
  };

  static defaultProps = {
    baths: null,
    beds: null,
  };

  constructor(props) {
    super(props);
    const { ctaButtonContext, user, listing, userInquiryDataCache, beds, baths } = this.props;
    const userEmail = listingUtils_getContactEmail(user, userInquiryDataCache);
    const userInfoFirstLastName =
      user.info.firstName && user.info.lastName ? `${user.info.firstName} ${user.info.lastName}` : '';
    let userMessage = userInquiryDataCache.text || listing.contact.defaultInquiryMessage || '';

    if (listing.waitlisted) {
      userMessage = 'Please add me to the waitlist for this listing. Thank you.';
    }

    if (ctaButtonContext === 'message') {
      userMessage = 'I would like more information about this listing. Thank you.';
    }

    const dedupedFloorPlans = controller.dedupeBedsBathsOptions(listing.floorplans);

    let numBaths = '';
    let numBeds = '';
    if (beds && baths) {
      numBaths = baths;
      numBeds = beds;
    } else {
      numBaths = dedupedFloorPlans[0] && dedupedFloorPlans[0].baths;
      numBeds = dedupedFloorPlans[0] && dedupedFloorPlans[0].beds;
    }

    this.userMessageInput = React.createRef();
    this.state = {
      inputValues: {
        userMessage,
        userEmail: userEmail || '',
        userName: userInquiryDataCache.name || userInfoFirstLastName || '',
        userPhoneNumber: userInquiryDataCache.phone || '',
        numBaths,
        numBeds,
        moveDate: dateUtils.formatDateMmddyyyy(new Date()),
        lowIncomeMessage: null,

        // always default to unchecked
        requestToApply: false,
      },
      inputErrors: {
        numBaths: null,
        numBeds: null,
        moveDate: null,
        userMessage: null,
        userEmail: controller.userHasMaskedEmail(userEmail) ? UPDATE_EMAIL_ERROR_MSG : null,
        userName: null,
        userPhoneNumber: null,
      },
      restrictedConfirmation: false, // Extra step for users to acknowledge on some restricted listings.
      formError: null,
      editingUserInfo: false,
      isDisabled: false,
      showTooltip: false,
    };
  }
  componentDidMount() {
    const { isMobile } = this.props;
    this.updateTextAreaHeight();
    if (isMobile) {
      window.addEventListener('click', this.handleClickCloseTooltip);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { contactSubmitted } = nextProps;

    if (contactSubmitted) {
      this.clearForm();
    }
  }
  componentDidUpdate(prevProps) {
    this.updateTextAreaHeight();

    // Update once cached inquiry data is fetched
    if (this.props.isCachedDataPending !== prevProps.isCachedDataPending) {
      this.setState({
        ...this.state,
        inputValues: this.populateUserInputValues(),
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickCloseTooltip);
  }
  populateUserInputValues = () => {
    const { baths, beds, ctaButtonContext, listing, user, userInquiryDataCache } = this.props;
    const userEmail = listingUtils_getContactEmail(user, userInquiryDataCache);
    const userInfoFirstLastName =
      user.info.firstName && user.info.lastName ? `${user.info.firstName} ${user.info.lastName}` : '';
    let userMessage = userInquiryDataCache.text || listing.contact.defaultInquiryMessage || '';

    if (listing.waitlisted) {
      userMessage = 'Please add me to the waitlist for this listing. Thank you.';
    }

    if (ctaButtonContext === 'message') {
      userMessage = 'I would like more information about this listing. Thank you.';
    }

    const dedupedFloorPlans = controller.dedupeBedsBathsOptions(listing.floorplans);

    let numBaths = '';
    let numBeds = '';
    if (beds && baths) {
      numBaths = baths;
      numBeds = beds;
    } else {
      numBaths = dedupedFloorPlans[0] && dedupedFloorPlans[0].baths;
      numBeds = dedupedFloorPlans[0] && dedupedFloorPlans[0].beds;
    }

    return {
      userMessage,
      userEmail: userEmail || '',
      userName: userInquiryDataCache.name || userInfoFirstLastName || '',
      userPhoneNumber: userInquiryDataCache.phone || '',
      numBaths,
      numBeds,
      moveDate: dateUtils.formatDateMmddyyyy(new Date()),
      lowIncomeMessage: null,

      // always default to unchecked
      requestToApply: false,
    };
  };

  updateTextAreaHeight = () => {
    // Dynamically change height of contact message text area if scroll height is larger than current height.
    const textElements = ReactDOM.findDOMNode(this.userMessageInput.current);
    if (textElements) {
      const userMessageTextArea = textElements.getElementsByTagName('textarea')[0];

      if (userMessageTextArea.scrollHeight > userMessageTextArea.style.height) {
        userMessageTextArea.style.height = userMessageTextArea.scrollHeight + 4 + 'px';
      }
    }
  };
  clearForm = () => {
    const { inputValues } = this.state;
    const newInputObj = assign({}, inputValues);
    newInputObj.moveDate = '';
    newInputObj.numBaths = '';
    newInputObj.numBeds = '';

    this.setState({ inputValues: newInputObj });
  };
  handleClickCloseTooltip = () => {
    const { showTooltip } = this.state;

    if (showTooltip) {
      this.setState({
        showTooltip: false,
      });
    }
  };
  handleMouseOver = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      // hover unavailable for mweb - use onClick instead
      return;
    }
    this.setState({ showTooltip: true });
  };
  handleMouseLeave = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      // hover unavailable for mweb - use onClick instead
      return;
    }

    this.setState({ showTooltip: false });
  };
  handleTooltipClick = (e) => {
    // mWeb: prevent closing and opening of tooltip (HPWEB-5430)
    e.stopPropagation();

    const { isMobile } = this.props;
    const { showTooltip } = this.state;

    if (!isMobile) {
      // hover unavailable for mweb - use onClick instead
      return;
    }
    this.setState({ showTooltip: !showTooltip });
  };
  handleEmailBlur = (e) => {
    const { inputValues } = this.state;
    const emailInput = e.target.value;
    const newInputValuesObj = assign({}, inputValues);
    newInputValuesObj.userEmail = emailInput;
    this.setState({
      inputValues: newInputValuesObj,
    });

    if (controller.userHasMaskedEmail(inputValues.userEmail)) {
      this.setError('userEmail', 'Please enter a valid email address.');
    }

    mailcheck.run({
      email: emailInput,
      suggested: (suggestion) => {
        const mailcheckHint = (
          <span
            className="suggestion"
            onClick={() => {
              const newEmail = suggestion.full;
              const newInputObj = assign({}, inputValues);
              newInputObj.userEmail = newEmail;
              this.setState({
                inputValues: newInputObj,
                mailcheckHint: '',
              });
            }}
          >
            Did you mean <Linker>{suggestion.full}</Linker>?
          </span>
        );
        this.setState({
          mailcheckHint,
        });
      },
      empty: () => {
        this.setState({
          mailcheckHint: '',
        });
      },
    });
  };
  handleEmailChange = (e) => {
    const { inputValues } = this.state;
    const emailInput = e.target.value;
    const newInputValuesObj = assign({}, inputValues);
    newInputValuesObj.userEmail = emailInput;
    this.setState({
      inputValues: newInputValuesObj,
    });
  };
  handleEditUserInfo = () => {
    this.setState({
      editingUserInfo: true,
    });
  };
  handleCalendarInput = (date) => {
    const { inputValues } = this.state;
    const newInputValuesObj = assign({}, inputValues);

    newInputValuesObj.moveDate = date;
    this.setState({ inputValues: newInputValuesObj });
  };
  handleTextInput = (field, e) => {
    const { inputValues } = this.state;
    const newInputValuesObj = assign({}, inputValues);

    newInputValuesObj[field] = e.target.value;
    this.setState({
      inputValues: newInputValuesObj,
    });
  };
  handleLowIncomeSelect = (value) => {
    const { inputValues } = this.state;
    const newInputValuesObj = assign({}, inputValues);
    newInputValuesObj.lowIncomeMessage = value;
    this.setState({
      inputValues: newInputValuesObj,
    });
  };
  handleApplyChange = () => {
    const { inputValues } = this.state;
    const newInputValuesObj = assign({}, inputValues);

    newInputValuesObj.requestToApply = !newInputValuesObj.requestToApply;

    if (newInputValuesObj.requestToApply) {
      this.handleAnalyticsTracking(gaEvents.HDP_CONTACT_CTA_APPLICATION_REQUEST);
    }

    this.setState({
      inputValues: newInputValuesObj,
    });
  };
  handleRestrictedConfirmationSelect = (value) => {
    this.setState({ restrictedConfirmation: value });
  };
  validateInput = (inputValues) => {
    const { listing } = this.props;
    const { restrictedConfirmation } = this.state;
    const { errorObj, isValid } = controller.validateContactFormInput({
      inputValues,
      listing,
      restrictedConfirmation,
    });

    this.setState(errorObj);
    return isValid;
  };
  handleSubmit = (e) => {
    const { onSubmitForm } = this.props;
    const { inputValues } = this.state;

    if (e) {
      e.preventDefault();
    }

    // if validation methods found an error, bail out of the submit process and show an error.
    // move into form component
    if (!this.validateInput(inputValues)) {
      return;
    }

    onSubmitForm(inputValues);
  };
  handleBedsBathsSelect = ({ beds, baths }) => {
    const { inputValues } = this.state;
    const newInputValuesObj = assign({}, inputValues);
    newInputValuesObj.numBeds = beds;
    newInputValuesObj.numBaths = baths;
    this.setState({
      inputValues: newInputValuesObj,
    });
  };
  setError = (type, msg) => {
    const errorsToUpdate = assign({}, this.state.inputErrors);
    errorsToUpdate[type] = msg;
    this.setState({ inputErrors: errorsToUpdate });
  };
  handleAnalyticsTracking = (trackingConstant) => {
    const { dispatch } = this.props;

    dispatch(analyticsEvent(trackingConstant));
  };
  render() {
    const {
      clientLoaded,
      emailErrorMessage,
      errorMessage,
      handleSetActiveModal = () => {},
      isCachedDataPending,
      isExperiment,
      isLoading,
      isPopup,
      listing,
      phoneErrorMessage,
      user,
      userInquiryDataCache,
    } = this.props;
    const { editingUserInfo, formError, inputErrors, inputValues, mailcheckHint, isDisabled, showTooltip } = this.state;
    const {
      address,
      contact,
      details,
      isApartmentBldg,
      listedBy,
      maloneLotIdEncoded,
      rentalApplicationStatus,
      trusted,
      waitlisted,
    } = listing;
    const isContactFormNotReadyForRender = !clientLoaded || isCachedDataPending === true;

    if (isContactFormNotReadyForRender) {
      return null;
    }

    const phoneRequired = contact.requiresPhone;
    const userInfoFirstLastName = `${user.info.firstName} ${user.info.lastName}`.trim();
    const userName = userInquiryDataCache.name || userInfoFirstLastName;
    const userEmail = listingUtils_getContactEmail(user, userInquiryDataCache);
    const userPhone = userInquiryDataCache.phone;
    const isMissingUserInfo = !user.loggedIn || !userName || !userEmail || (phoneRequired && !userPhone);
    const maskedUserEmail = controller.userHasMaskedEmail(userEmail);
    let sendButtonLabel = 'Send message';

    if (waitlisted) {
      sendButtonLabel = 'Add me to the waitlist';
    }

    return (
      <>
        <form className={cx('ContactForm', { 'ContactForm-popup': isPopup })}>
          <StyledContactListingRestrictions
            onSelect={this.handleLowIncomeSelect}
            onConfirmation={this.handleRestrictedConfirmationSelect}
            listing={listing}
            isPopup={isPopup}
          />
          {contact.requiresMoveDate && (
            <StyledCalendarContainer>
              <MoveInCalendar
                help={inputErrors.moveDate}
                hpxStyle={inputErrors.moveDate ? 'error' : null}
                onCalendarInput={this.handleCalendarInput}
                required
                toggleSubmitDisabled={() => {
                  this.setState({
                    isDisabled: !isDisabled,
                  });
                }}
                value={inputValues.moveDate}
              />
            </StyledCalendarContainer>
          )}

          {contact.requiresNumBeds && (
            <BedsBathsSelect
              listing={listing}
              onBedsBathsSelect={this.handleBedsBathsSelect}
              beds={inputValues.numBeds}
              baths={inputValues.numBaths}
            />
          )}
          {(contact.requiresMoveDate || contact.requiresNumBeds) && <div className="ContactForm-delim" />}
          <Input
            id="user-message-input"
            help={inputErrors.userMessage}
            hpxStyle={inputErrors.userMessage ? 'error' : null}
            label="Customize message"
            maxLength={750}
            onChange={this.handleTextInput.bind(null, 'userMessage')}
            ref={this.userMessageInput}
            required={contact.requiresMessage}
            type="textarea"
            value={inputValues.userMessage}
          />

          {!isApartmentBldg && shouldShowRequestToApplyButton(rentalApplicationStatus) && (
            <Fragment>
              <div
                className="ContactForm-ready-top"
                onMouseOver={this.handleMouseOver}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleTooltipClick}
              >
                <ReadyToApplyText size="sm">Ready to apply?</ReadyToApplyText>
                <img src={showTooltip ? IconMoreInfoActive : IconMoreInfo} width="16px" height="16px" />
              </div>
              {showTooltip && (
                <StyledContactFormTipText className="ContactForm-tip">
                  We’ll let them know that you’d like information regarding their application process. You are in no way
                  obligated to apply.
                </StyledContactFormTipText>
              )}
              <div className="ContactForm-ready-bottom">
                <StyledCheckbox
                  label="Send me an application form"
                  checked={Boolean(inputValues.requestToApply)}
                  onChange={this.handleApplyChange}
                  textSize="md"
                />
              </div>
            </Fragment>
          )}

          {isMissingUserInfo || editingUserInfo ? (
            <Fragment>
              <Input
                id="name-input"
                className="ContactForm-name-padding"
                help={inputErrors.userName}
                hpxStyle={inputErrors.userName ? 'error' : null}
                label="Your name"
                maxLength={50}
                onChange={this.handleTextInput.bind(null, 'userName')}
                required={contact.requiresName}
                value={inputValues.userName}
                autoComplete="name"
              />
              <StyledEmailInput
                id="email-input"
                help={emailErrorMessage || inputErrors.userEmail || mailcheckHint}
                hpxStyle={inputErrors.userEmail ? 'error' : null}
                label="Your email"
                maxLength={50}
                onChange={this.handleEmailChange}
                onBlur={this.handleEmailBlur}
                required={contact.requiresEmail}
                name="email"
                type="email"
                value={inputValues.userEmail}
                autoComplete="email"
              />
              <StyledInputPhoneNumber
                id="phone-input"
                help={phoneErrorMessage || inputErrors.userPhoneNumber}
                hpxStyle={inputErrors.userPhoneNumber ? 'error' : null}
                label="Your phone number"
                maxLength={15}
                onChange={this.handleTextInput.bind(null, 'userPhoneNumber')}
                required={contact.requiresPhone}
                value={inputValues.userPhoneNumber}
                autoComplete="tel-national"
              />
            </Fragment>
          ) : (
            <ContactUserInfo
              onEditUserInfo={this.handleEditUserInfo}
              emailErrorMessage={emailErrorMessage}
              phoneErrorMessage={phoneErrorMessage}
            />
          )}
          <StyledSubmitContainer>
            <Button
              full
              size="lg"
              btnType="text-color"
              onClick={this.handleSubmit}
              disabled={isLoading || maskedUserEmail || isDisabled}
            >
              {isLoading ? 'Sending Message..' : sendButtonLabel}
            </Button>
            {(errorMessage || formError) && (
              <Row>
                <StyledAlertDiv>{errorMessage || formError}</StyledAlertDiv>
              </Row>
            )}
          </StyledSubmitContainer>
        </form>
        {isExperiment && (
          <div className={cx('ContactForm', { 'ContactForm-popup': isPopup }, 'ContactForm-listed-by')}>
            <ContactListedByV2
              address={address}
              details={details}
              isApartmentBldg={isApartmentBldg}
              isExperiment={isExperiment}
              isPopup={isPopup}
              listedBy={listedBy}
              maloneLotIdEncoded={maloneLotIdEncoded}
              onSetActiveModal={handleSetActiveModal}
              trusted={trusted}
              waitlisted={waitlisted}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clientLoaded: state.app.clientLoaded,
    ctaButtonContext: state.user.ctaButtonContext,
    isMobile: state.app.device.screenWidth === 'sm',
    listing: state.currentListingDetails.currentListing,
    user: state.user,
    userInquiryDataCache: state.user.inquiryDataCache,
  };
};

export default connect(mapStateToProps)(ContactForm);
