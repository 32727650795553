import React, { useCallback } from 'react';
import * as S from './styles';
import TourStatusModule from 'app/shared/modules/hdp/InstantTour/components/TourStatusModule';
import { useSelector } from 'react-redux';
import ContactFill from 'images/icons/contact-fill.svg';
import { useDispatch } from 'react-redux';
import { STEPS, TOUR_TYPES } from '../../constants';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';
import PromptLogin from 'app/shared/modules/auth/PromptLogin/component';
import UserActions from 'app/shared/flux/actions/UserActions';
import type { RootReduxState } from 'app/shared/flux/reducers';
import { useInstantTourForm } from '../../hooks';

interface ConfirmationStepProps {
  onActionPopup?: (option: string) => void;
}

const ConfirmationStep = ({ onActionPopup = () => {} }: ConfirmationStepProps) => {
  const { updateInstantTourForm } = useInstantTourForm();
  const listing = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing);
  const instantTourForm = useSelector((state: RootReduxState) => state.currentListingDetails.instantTourForm);
  const step = instantTourForm?.step;
  const selectedTourType = instantTourForm?.tourType;
  const dispatch = useDispatch();

  const onContactPropertyClick = useCallback(() => {
    onActionPopup('contactTourStatusModule');
    dispatch(analyticsEvent(gaEvents.HPTOUR_CONTACT_SUCCESS));
  }, [dispatch, onActionPopup]);

  const onRescheduleTourClick = useCallback(() => {
    updateInstantTourForm({ step: STEPS.MODIFY_TOUR });
    dispatch(analyticsEvent(gaEvents.HPTOUR_RESCHEDULE_TOUR_SUCCESS));
  }, [dispatch, updateInstantTourForm]);

  const handleLoginSuccess = useCallback(() => {
    dispatch(UserActions.hpTours.fetchScheduledToursForUser());
    updateInstantTourForm({ step: STEPS.CONFIRMATION });
    dispatch(analyticsEvent(gaEvents.HPTOUR_LOGIN_SUCCESS));
  }, [dispatch, updateInstantTourForm]);

  // user signed out but created tour with account associated email
  if (step === STEPS.PROMPT_LOGIN) {
    return (
      <S.ConfirmationStatus>
        <PromptLogin
          title={'Sign In to Your Existing Account'}
          showDescription={false}
          shouldRedirect={false}
          handleLoginSuccess={handleLoginSuccess}
        />
      </S.ConfirmationStatus>
    );
  }

  if (step === STEPS.CANCEL_SUCCESS) {
    return (
      <>
        <S.ConfirmationStatus aria-live="assertive">
          <S.Header>Your tour has been cancelled.</S.Header>
          <S.MessageText size="lg">You may receive an email confirmation for this cancellation.</S.MessageText>
          <TourStatusModule />
        </S.ConfirmationStatus>
      </>
    );
  }

  return (
    <>
      <S.ConfirmationStatus aria-live="assertive">
        <S.EnvelopeIcon alt="" src={ContactFill} />
        <S.Header>
          {step === STEPS.RESCHEDULE_SUCCESS
            ? 'Your tour has changed.'
            : `Your tour ${selectedTourType === TOUR_TYPES.SELF_GUIDED ? 'has been requested.' : 'is booked.'}`}
        </S.Header>

        {(selectedTourType === TOUR_TYPES.IN_PERSON || selectedTourType === TOUR_TYPES.LIVE_VIDEO) && (
          <S.MessageText>
            You may receive one or more emails from the property manager or companies that help with scheduling. You may
            have to confirm the tour details again.
          </S.MessageText>
        )}
        {selectedTourType === TOUR_TYPES.LIVE_VIDEO && (
          <S.MessageText>
            The property will give you instructions for the video chat before your video tour, usually by email.
          </S.MessageText>
        )}
        {selectedTourType === TOUR_TYPES.SELF_GUIDED && (
          <>
            <S.MessageText>
              You&apos;ll receive one or more emails from the property manager or companies that help with scheduling.
            </S.MessageText>
            <S.MessageText>
              The property will confirm your tour and give you instructions before your tour, usually by email.
            </S.MessageText>
            <S.MessageText>
              You may be required to complete more steps before you arrive at the property, such as uploading a photo of
              your government-issued identification for security purposes.
            </S.MessageText>
          </>
        )}
        <TourStatusModule
          listing={listing}
          onContactPropertyClick={onContactPropertyClick}
          onRescheduleTourClick={onRescheduleTourClick}
        />
      </S.ConfirmationStatus>
    </>
  );
};

export default ConfirmationStep;
