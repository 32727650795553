// @ts-nocheck
/* eslint-enable */
/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DayAndTimeDisplay from 'app/shared/modules/hdp/InstantTour/components/DayAndTimeDisplay';
import Text from 'app/shared/core/Text';
import Divider from 'app/shared/modules/Divider';
import SpinnerDots from 'app/shared/modules/SpinnerDots';
import problemCircleFillRedIcon from 'images/icons/problem-circle-fill-red.svg';
import inPersonTourIcon from 'images/icons/in-person-tour.svg';
import selfGuideTourIcon from 'images/icons/self-guided-tour.svg';
import liveVideoTourIcon from 'images/icons/live-video-tour.svg';
import IconMailAccent from 'images/icons/mail-accent.svg';
import IconCalendarReschedule from 'images/icons/calendar-reschedule.svg';
import IconCalendarRescheduleRed from 'images/icons/calendar-reschedule-red.svg';
import hideIcon from 'images/icons/hide.svg';

import dateUtils from 'app/shared/utils/dateUtils';
import api from 'app/shared/utils/api';
import { getScheduledTour } from 'app/shared/utils/listingUtils';
import { STEPS, TOUR_TYPES } from 'app/shared/modules/hdp/InstantTour/constants';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

import * as S from './styles';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import UserActions from 'app/shared/flux/actions/UserActions';
import {
  useInstantTourForm,
  useScheduledTourProvider,
  useSelectedTourProvider,
} from 'app/shared/modules/hdp/InstantTour/hooks';
import type { ListingDetails } from 'app/types/listingDetails.type';

interface TourStatusRef {
  focusContactPropertyButton: () => void;
  focusRescheduleOrBookAnotherButton: () => void;
}

interface TourStatusModuleProps {
  isRenderedOnHdp?: boolean;
  listing?: ListingDetails | null;
  onContactPropertyClick?: () => void;
  onBookAnotherTourClick?: () => void;
  onRescheduleTourClick?: () => void;
  setIsPrimaryBtnDisabled?: (disabled: boolean) => void;
}

const TourStatusModule: React.ForwardRefRenderFunction<TourStatusRef, TourStatusModuleProps> = (
  {
    isRenderedOnHdp = false,
    onContactPropertyClick = () => {},
    onBookAnotherTourClick = () => {},
    onRescheduleTourClick = () => {},
    setIsPrimaryBtnDisabled = () => {},
  },
  ref,
) => {
  const dispatch = useDispatch();
  const { updateInstantTourForm } = useInstantTourForm();
  const isMobile = useSelector((state) => state.app.device.isMobile);
  const { aliasEncoded, address, displayName } = useSelector((state) => state.currentListingDetails.currentListing);
  const { zoneId } = useSelector((state) => state.currentListingDetails.availableToursForListing);
  const { dateOnly: selectedDateOnly } = useSelectedTourProvider();
  const { dateOnly: scheduledDateOnly, supportsCancel: scheduledSupportsCancel } = useScheduledTourProvider();
  const {
    selectedDateTime,
    step,
    tourType: selectedTourType,
  } = useSelector((state) => state.currentListingDetails.instantTourForm);
  const scheduledToursForUser = useSelector((state) => state.user.scheduledTours);
  const {
    tourId,
    tourType: scheduledTourType,
    startTime: scheduledDateWithOrWithoutTime,
  } = getScheduledTour(aliasEncoded, scheduledToursForUser);
  const scheduledDateTime = dateUtils.appendTimeISOSubstringIfMissing(scheduledDateWithOrWithoutTime);
  const [isBookAnotherTourFlow, setIsBookAnotherTourFlow] = useState(
    isRenderedOnHdp && dateUtils.isDateInThePast(scheduledDateTime),
  );
  const [isAvailTimesReqLoading, setIsAvailTimesReqLoading] = useState(false);
  const [isSelectNewTimeLoading, setIsSelectNewTimeLoading] = useState(false);
  const [isCancelTourLoading, setIsCancelTourLoading] = useState(false);
  const { street } = address;
  const displayNameToRender = displayName === street ? displayName : `${displayName}, ${street}`;
  const contactPropertyButtonRef = useRef(null);
  const rescheduleOrBookAnotherTourButtonRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focusContactPropertyButton: () => {
      contactPropertyButtonRef?.current?.focus();
    },
    focusRescheduleOrBookAnotherButton: () => {
      rescheduleOrBookAnotherTourButtonRef?.current?.focus();
    },
  }));

  useEffect(() => {
    const interval = isRenderedOnHdp
      ? setInterval(() => {
          setIsBookAnotherTourFlow(dateUtils.isDateInThePast(scheduledDateTime));
        }, 1000)
      : null;

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [isRenderedOnHdp, scheduledDateTime]);

  const getBookedTourInfoForAria = useCallback(() => {
    let bookedTourInfo = '';
    const scheduledDate = new Date(scheduledDateTime);
    const dateOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    let tourTypeToRender = 'in-person tour';
    if (scheduledTourType === TOUR_TYPES.SELF_GUIDED) {
      tourTypeToRender = 'self-guided tour';
    } else if (scheduledTourType === TOUR_TYPES.LIVE_VIDEO) {
      tourTypeToRender = 'live video tour';
    }

    bookedTourInfo = `booked ${tourTypeToRender} for ${scheduledDate.toLocaleDateString('en-US', dateOptions)}`;
    if (!scheduledDateOnly) {
      bookedTourInfo += ` at ${scheduledDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      })}`;
    }

    return bookedTourInfo;
  }, [scheduledDateOnly, scheduledDateTime, scheduledTourType]);

  const handleSelectNewTimeCTAClick = useCallback(() => {
    if (step === STEPS.CANCEL_SUCCESS) {
      dispatch(analyticsEvent(gaEvents.HPTOUR_CANCEL_CHOOSE_NEW_TIME));
    } else {
      dispatch(analyticsEvent(gaEvents.HPTOUR_SCHEDULE_TOUR_ERROR_CHOOSE_NEW_TIME));
    }

    setIsSelectNewTimeLoading(true);
    dispatch(ListingEngineActions.hpTours.fetchAvailableToursForListing(aliasEncoded, selectedTourType))
      .then(() => {
        LupdateInstantTourForm({
          step: STEPS.SELECT_DATE_TIME,
          selectedDateTime: '',
        });
      })
      .catch((e) => {
        let newStep = '';

        if (e?.message === 'Request failed with status code 404') {
          newStep = STEPS.SELECT_DATE_TIME;
        } else {
          newStep = STEPS.SYSTEM_ERROR;
          dispatch(analyticsEvent(gaEvents.HPTOUR_SYSTEM_ERROR, { label: e?.message }));
        }

        dispatch(ListingEngineActions.hpTours.clearAvailableToursForListing());
        updateInstantTourForm({
          step: newStep,
          selectedDateTime: '',
        });
      });
  }, [aliasEncoded, dispatch, selectedTourType, step, updateInstantTourForm]);

  const handleGoBackCTAClick = useCallback(() => {
    dispatch(analyticsEvent(gaEvents.HPTOUR_SCHEDULE_TOUR_ERROR_GO_BACK));
    setIsSelectNewTimeLoading(true);
    dispatch(ListingEngineActions.hpTours.fetchAvailableToursForListing(aliasEncoded, selectedTourType))
      .then(() => {
        updateInstantTourForm({
          step: STEPS.MODIFY_TOUR,
          selectedDateTime: '',
        });
      })
      .catch((e) => {
        let newStep = '';
        if (e.message === 'Request failed with status code 404') {
          newStep = STEPS.MODIFY_TOUR;
        } else {
          newStep = STEPS.SYSTEM_ERROR;
          dispatch(analyticsEvent(gaEvents.HPTOUR_SYSTEM_ERROR, { label: e.message }));
        }

        dispatch(ListingEngineActions.hpTours.clearAvailableToursForListing());
        updateInstantTourForm({
          step: newStep,
          selectedDateTime: '',
        });
      });
  }, [aliasEncoded, dispatch, selectedTourType, updateInstantTourForm]);

  const handleCancelTourClick = useCallback(() => {
    setIsPrimaryBtnDisabled(true);
    setIsCancelTourLoading(true);
    dispatch(analyticsEvent(gaEvents.HPTOUR_CANCEL_TOUR_SUBMIT));
    dispatch(api.hpToursV3.cancel(tourId))
      .then((res) => {
        if (res.success) {
          dispatch(UserActions.hpTours.fetchScheduledToursForUser());
          dispatch(ListingEngineActions.hpTours.clearAvailableToursForListing());
          updateInstantTourForm({
            step: STEPS.CANCEL_SUCCESS,
            selectedDate: '',
            selectedDateTime: '',
          });

          dispatch(analyticsEvent(gaEvents.HPTOUR_CANCEL_TOUR_SUCCESS));
        } else {
          updateInstantTourForm({ step: STEPS.CANCEL_ERROR });
          dispatch(analyticsEvent(gaEvents.HPTOUR_CANCEL_TOUR_ERROR, { label: res.message }));
        }
      })
      .catch((e) => {
        updateInstantTourForm({ step: STEPS.CANCEL_ERROR });
        dispatch(analyticsEvent(gaEvents.HPTOUR_CANCEL_TOUR_ERROR, { label: e.message }));
      })
      .finally(() => {
        setIsPrimaryBtnDisabled(false);
      });
  }, [dispatch, setIsPrimaryBtnDisabled, tourId, updateInstantTourForm]);

  const handleRescheduleOrBookAnotherTourClick = useCallback(() => {
    setIsAvailTimesReqLoading(true);
    dispatch(
      ListingEngineActions.hpTours.fetchAvailableToursForListing(
        aliasEncoded,
        scheduledTourType,
        scheduledDateWithOrWithoutTime,
      ),
    )
      .then(() => {
        if (isBookAnotherTourFlow) {
          onBookAnotherTourClick();
        } else {
          onRescheduleTourClick();
        }
      })
      .catch((e) => {
        updateInstantTourForm({ step: STEPS.SYSTEM_ERROR });
        dispatch(analyticsEvent(gaEvents.HPTOUR_SYSTEM_ERROR, { label: e?.message }));
      })
      .finally(() => {
        if (isRenderedOnHdp) {
          setIsAvailTimesReqLoading(false);
        }
      });
  }, [
    dispatch,
    aliasEncoded,
    scheduledTourType,
    scheduledDateWithOrWithoutTime,
    isBookAnotherTourFlow,
    onBookAnotherTourClick,
    onRescheduleTourClick,
    updateInstantTourForm,
    isRenderedOnHdp,
  ]);

  if (step === STEPS.SCHEDULE_TOUR_ERROR && !isRenderedOnHdp) {
    return (
      <S.TourStatusContainer showBorder noCenter errorBoarder>
        <S.IconAndTextContainer>
          <S.Icon alt="" src={problemCircleFillRedIcon} />
          <span>
            <Text style={{ fontWeight: 'bold' }} htmlTag="p" size="lg">
              We couldn&apos;t finish booking this tour
            </Text>
            <Text htmlTag="p">
              This tour time may no longer be available or something may have gone wrong. Please try again.
            </Text>
          </span>
        </S.IconAndTextContainer>
        <S.CTAContainer>
          <S.TextButton disabled={isSelectNewTimeLoading} onClick={handleSelectNewTimeCTAClick}>
            {isSelectNewTimeLoading ? (
              <SpinnerDots />
            ) : (
              <>
                <S.Icon alt="" src={IconCalendarRescheduleRed} />
                <Text>Select a new time</Text>
              </>
            )}
          </S.TextButton>
        </S.CTAContainer>
      </S.TourStatusContainer>
    );
  }

  if (step === STEPS.MODIFY_TOUR && !isRenderedOnHdp) {
    return (
      <S.TourStatusContainer noCenter isCancel>
        <Text style={{ fontWeight: 'bold' }} htmlTag="h2" size="lg">
          Your booked tour
        </Text>
        <Text htmlTag="p">
          {scheduledTourType === TOUR_TYPES.IN_PERSON && 'In-person tour'}
          {scheduledTourType === TOUR_TYPES.SELF_GUIDED && 'Self-guided tour'}
          {scheduledTourType === TOUR_TYPES.LIVE_VIDEO && 'Live video tour'}
        </Text>
        <DayAndTimeDisplay dateTimeToDisplay={scheduledDateTime} dateOnly={scheduledDateOnly} zoneId={zoneId} />
        <Text htmlTag="p">{displayNameToRender}</Text>
        {scheduledSupportsCancel && (
          <S.TextButton
            aria-label={`Cancel ${getBookedTourInfoForAria()}`}
            disabled={isCancelTourLoading}
            isCancel
            onClick={handleCancelTourClick}
          >
            {isCancelTourLoading ? (
              <SpinnerDots />
            ) : (
              <>
                <S.Icon alt="" src={hideIcon} />
                <Text>Cancel tour</Text>
              </>
            )}
          </S.TextButton>
        )}
      </S.TourStatusContainer>
    );
  }

  if (step === STEPS.CANCEL_SUCCESS && !isRenderedOnHdp) {
    return (
      <S.TourStatusContainer showBorder noCenter role="status">
        <S.IconAndTextContainer>
          <S.Icon alt="" src={hideIcon} />
          <span style={{ fontWeight: 'bold' }}>
            <Text>
              Cancelled:
              {selectedTourType === TOUR_TYPES.IN_PERSON && ' In-person tour'}
              {selectedTourType === TOUR_TYPES.SELF_GUIDED && ' Self-guided tour'}
              {selectedTourType === TOUR_TYPES.LIVE_VIDEO && ' Live video tour'}
            </Text>
            <Text htmlTag="p">{displayNameToRender}</Text>
          </span>
        </S.IconAndTextContainer>
        <S.CTAContainer>
          <S.TextButton disabled={isSelectNewTimeLoading} onClick={handleSelectNewTimeCTAClick}>
            {isSelectNewTimeLoading ? (
              <SpinnerDots />
            ) : (
              <>
                <S.Icon alt="" src={IconCalendarReschedule} />
                <Text>Choose a new tour time</Text>
              </>
            )}
          </S.TextButton>
        </S.CTAContainer>
      </S.TourStatusContainer>
    );
  }

  if ((step === STEPS.CANCEL_ERROR || step === STEPS.RESCHEDULE_ERROR) && !isRenderedOnHdp) {
    return (
      <S.TourStatusContainer showBorder noCenter errorBoarder>
        <S.IconAndTextContainer>
          <S.Icon alt="" src={problemCircleFillRedIcon} />
          <span>
            <Text style={{ fontWeight: 'bold' }} size="lg">
              We couldn&apos;t {step === STEPS.CANCEL_ERROR ? 'cancel' : 'reschedule'} this tour
            </Text>
            <Text htmlTag="p">
              Please try again or contact the property to {step === STEPS.CANCEL_ERROR ? 'cancel' : 'reschedule'}.
            </Text>
          </span>
        </S.IconAndTextContainer>
        <S.CTAContainer>
          <S.TextButton disabled={isSelectNewTimeLoading} onClick={handleGoBackCTAClick}>
            {isSelectNewTimeLoading ? (
              <SpinnerDots />
            ) : (
              <>
                <S.Icon alt="" src={IconCalendarRescheduleRed} />
                <Text>Go back</Text>
              </>
            )}
          </S.TextButton>
        </S.CTAContainer>
      </S.TourStatusContainer>
    );
  }

  if ((step === STEPS.CONFIRMATION || step === STEPS.RESCHEDULE_SUCCESS) && !isRenderedOnHdp) {
    return (
      <S.TourStatusContainer showBorder noCenter role="status">
        <S.IconAndTextContainer>
          {selectedTourType === TOUR_TYPES.IN_PERSON && <S.Icon alt="" src={inPersonTourIcon} />}
          {selectedTourType === TOUR_TYPES.SELF_GUIDED && <S.Icon alt="" src={selfGuideTourIcon} />}
          {selectedTourType === TOUR_TYPES.LIVE_VIDEO && <S.Icon alt="" src={liveVideoTourIcon} />}
          <span>
            <Text style={{ fontWeight: 'bold' }} htmlTag="p">
              {selectedTourType === TOUR_TYPES.IN_PERSON && 'In-person tour'}
              {selectedTourType === TOUR_TYPES.SELF_GUIDED && 'Self-guided tour'}
              {selectedTourType === TOUR_TYPES.LIVE_VIDEO && 'Live video tour'}
            </Text>
            <Text style={{ fontWeight: 'bold' }}>{displayNameToRender}</Text>
            <DayAndTimeDisplay dateTimeToDisplay={selectedDateTime} dateOnly={selectedDateOnly} zoneId={zoneId} />
          </span>
        </S.IconAndTextContainer>
        <S.CTAContainer>
          <S.TextButton ref={contactPropertyButtonRef} onClick={onContactPropertyClick}>
            <S.Icon alt="" src={IconMailAccent} />
            <Text>Contact property</Text>
          </S.TextButton>
          {isMobile && <Divider />}
          <S.TextButton
            disabled={isAvailTimesReqLoading}
            ref={rescheduleOrBookAnotherTourButtonRef}
            onClick={handleRescheduleOrBookAnotherTourClick}
          >
            {isAvailTimesReqLoading ? (
              <SpinnerDots />
            ) : (
              <>
                <S.Icon alt="" src={IconCalendarReschedule} />
                <Text>Reschedule tour</Text>
              </>
            )}
          </S.TextButton>
        </S.CTAContainer>
      </S.TourStatusContainer>
    );
  }

  if (isRenderedOnHdp) {
    return (
      <S.TourStatusContainer showBorder noCenter isRenderedOnHdp>
        {scheduledTourType === TOUR_TYPES.IN_PERSON && <S.Icon alt="" src={inPersonTourIcon} />}
        {scheduledTourType === TOUR_TYPES.SELF_GUIDED && <S.Icon alt="" src={selfGuideTourIcon} />}
        {scheduledTourType === TOUR_TYPES.LIVE_VIDEO && <S.Icon alt="" src={liveVideoTourIcon} />}
        <Text style={{ fontWeight: 'bold' }} htmlTag="p">
          {scheduledTourType === TOUR_TYPES.IN_PERSON && (
            <>{isRenderedOnHdp ? 'Booked in-person tour' : 'In-person tour'}</>
          )}
          {scheduledTourType === TOUR_TYPES.SELF_GUIDED && (
            <>{isRenderedOnHdp ? 'Booked self-guided tour' : 'Self-guided tour'}</>
          )}
          {scheduledTourType === TOUR_TYPES.LIVE_VIDEO && (
            <>{isRenderedOnHdp ? 'Booked live video tour' : 'Live video tour'}</>
          )}
        </Text>
        <DayAndTimeDisplay dateTimeToDisplay={scheduledDateTime} dateOnly={scheduledDateOnly} zoneId={zoneId} />
        <S.CTAContainer>
          <S.TextButton ref={contactPropertyButtonRef} onClick={onContactPropertyClick}>
            <S.Icon alt="" src={IconMailAccent} />
            <Text>Contact property</Text>
          </S.TextButton>
          {isMobile && <Divider />}
          <S.TextButton
            aria-label={isBookAnotherTourFlow ? 'Book another tour' : `Reschedule ${getBookedTourInfoForAria()}`}
            disabled={isAvailTimesReqLoading}
            ref={rescheduleOrBookAnotherTourButtonRef}
            onClick={handleRescheduleOrBookAnotherTourClick}
          >
            {isAvailTimesReqLoading ? (
              <SpinnerDots />
            ) : (
              <>
                <S.Icon alt="" src={IconCalendarReschedule} />
                <Text>{isBookAnotherTourFlow ? 'Book another tour' : 'Reschedule tour'}</Text>
              </>
            )}
          </S.TextButton>
        </S.CTAContainer>
      </S.TourStatusContainer>
    );
  }

  // When TourStatusModule is rendered on step components SelectDateTime or ContactInfoAndConfirm
  return (
    <S.TourStatusContainer>
      {selectedTourType === TOUR_TYPES.IN_PERSON && <S.Icon alt="" src={inPersonTourIcon} />}
      {selectedTourType === TOUR_TYPES.SELF_GUIDED && <S.Icon alt="" src={selfGuideTourIcon} />}
      {selectedTourType === TOUR_TYPES.LIVE_VIDEO && <S.Icon alt="" src={liveVideoTourIcon} />}
      <Text style={{ fontWeight: 'bold' }} htmlTag="p">
        {selectedTourType === TOUR_TYPES.IN_PERSON && 'In-person tour'}
        {selectedTourType === TOUR_TYPES.SELF_GUIDED && 'Self-guided tour'}
        {selectedTourType === TOUR_TYPES.LIVE_VIDEO && 'Live video tour'}
      </Text>
      <Text style={{ fontWeight: 'bold' }}>{displayNameToRender}</Text>
      <DayAndTimeDisplay dateTimeToDisplay={selectedDateTime} dateOnly={selectedDateOnly} zoneId={zoneId} />
    </S.TourStatusContainer>
  );
};

export default React.forwardRef(TourStatusModule);
