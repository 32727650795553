// @ts-nocheck
/* eslint-enable */
/* eslint-disable @zg-rentals/workspace-imports */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useYieldCallback } from '@zillow/yield-callback';
import NoToursAvailableError from '../NoToursAvailableError';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';
import dateUtils from 'app/shared/utils/dateUtils';
import circleArrowLeftSmall from 'images/icons/circle-arrow-left-small.svg';
import circleArrowRightSmall from 'images/icons/circle-arrow-right-small.svg';
import { useInstantTourForm, useSelectedTourProvider } from '../../hooks';
import { STEPS, TOUR_TYPES } from '../../constants';
import * as S from './styles';
import Text from 'app/shared/core/Text';
import type { RootReduxState } from 'app/shared/flux/reducers';

const computeNewCarouselIdx = (localDate, datesInCarousel, numBtnsInCarousel) => {
  if (!localDate || datesInCarousel.length === 0 || !datesInCarousel.includes(localDate)) {
    return 0;
  }

  let carouselIdx;
  for (let idx = 0; idx < datesInCarousel.length; idx++) {
    if (idx % numBtnsInCarousel === 0) {
      carouselIdx = idx;
    }

    if (localDate === datesInCarousel[idx]) {
      break;
    }
  }

  return carouselIdx;
};

// TODO: continue improving accessibility by make kb navigating similar to radio groups.
const DateTimePicker = ({ isAvailTimesReqLoading = false, setIsPrimaryBtnDisabled = () => {} }) => {
  const { updateInstantTourForm } = useInstantTourForm();
  const isMobile = useSelector((state) => state.app.device.isMobile);
  // used to track when isMobile value changes.
  const [isMobileTracker, setIsMobileTracker] = useState(isMobile);
  const numBtnsInCarousel = isMobile ? 3 : 4;
  const selectedDateBtnRef = useRef(null);
  const {
    step,
    selectedDate,
    selectedDateTime,
    tourType: selectedTourType,
  } = useSelector((state) => state.currentListingDetails.instantTourForm);
  const { availableToursMap, zoneId } = useSelector(
    (state: RootReduxState) => state.currentListingDetails.availableToursForListing,
  );
  const datesInCarousel = Object.keys(availableToursMap);
  const noToursAvailable = datesInCarousel.length === 0;
  // Idx used to determine which set of dates to display on the carousel.
  const [carouselIdx, setCarouselIdx] = useState(0);
  const { dateOnly } = useSelectedTourProvider();

  useEffect(() => {
    // Need to reset carousel start if device screen changes to prevent array out of bounds err.
    if (isMobileTracker !== isMobile) {
      setCarouselIdx(computeNewCarouselIdx(selectedDate, datesInCarousel, numBtnsInCarousel));
      setIsMobileTracker(isMobile);
    }
  }, [datesInCarousel, isMobile, isMobileTracker, numBtnsInCarousel, selectedDate]);

  const getInitialSelectedDateTime = useCallback(
    (dateToUse) => {
      if (noToursAvailable) {
        return '';
      }

      if (!selectedDateTime) {
        return availableToursMap[dateToUse][0];
      }

      let newSelectedDateTime = '';
      const selectedHour = selectedDateTime ? new Date(selectedDateTime).getHours() : null;
      const selectedMinute = selectedDateTime ? new Date(selectedDateTime).getMinutes() : null;

      availableToursMap[dateToUse].forEach((availableDateTime) => {
        const availableHour = new Date(availableDateTime).getHours();
        const availableMinute = new Date(availableDateTime).getMinutes();

        if (selectedHour === availableHour && selectedMinute === availableMinute) {
          newSelectedDateTime = availableDateTime;
        }
      });

      if (!newSelectedDateTime) {
        newSelectedDateTime = availableToursMap[dateToUse][0];
      }

      return newSelectedDateTime;
    },
    [availableToursMap, noToursAvailable, selectedDateTime],
  );

  useEffect(() => {
    if (noToursAvailable) {
      setIsPrimaryBtnDisabled(true);
      return;
    } else {
      setIsPrimaryBtnDisabled(false);
    }

    const initialSelectedDate = datesInCarousel.includes(selectedDate) ? selectedDate : datesInCarousel[0];
    const initialSelectedDateTime = getInitialSelectedDateTime(initialSelectedDate);

    updateInstantTourForm({
      selectedDate: initialSelectedDate,
      selectedDateTime: initialSelectedDateTime,
    });

    setCarouselIdx(computeNewCarouselIdx(initialSelectedDate, datesInCarousel, numBtnsInCarousel));
    // We only want this useEffect to run on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDate && step === STEPS.SELECT_DATE_TIME) {
      selectedDateBtnRef?.current?.focus();
    }
  }, [selectedDate, step]);

  const yieldArrowClick = useYieldCallback((newCarouselIdx) => {
    const newSelectedDate = datesInCarousel[newCarouselIdx];
    const newSelectedTime = availableToursMap[newSelectedDate][0];
    setCarouselIdx(newCarouselIdx);

    updateInstantTourForm({
      selectedDate: newSelectedDate,
      selectedDateTime: newSelectedTime,
    });
  });

  const handleArrowClick = useCallback(
    (newCarouselIdx) => {
      yieldArrowClick(newCarouselIdx);
    },
    [yieldArrowClick],
  );

  const handleBackArrowClick = useCallback(() => {
    handleArrowClick(carouselIdx - numBtnsInCarousel);
  }, [handleArrowClick, carouselIdx, numBtnsInCarousel]);

  const handleNextArrowClick = useCallback(() => {
    handleArrowClick(carouselIdx + numBtnsInCarousel);
  }, [handleArrowClick, carouselIdx, numBtnsInCarousel]);

  const yieldHandleDateSelect = useYieldCallback((dateBtnValue) => {
    updateInstantTourForm({
      selectedDate: dateBtnValue,
      selectedDateTime: availableToursMap[dateBtnValue][0],
    });

    if (dateOnly) {
      setIsPrimaryBtnDisabled(false);
    }
  });

  const handleDateSelect = useCallback(
    (e) => {
      const dateBtnValue = e.currentTarget.value;
      if (selectedDate === dateBtnValue) {
        return;
      }

      yieldHandleDateSelect(dateBtnValue);
    },
    [yieldHandleDateSelect, selectedDate],
  );

  const yieldHandleTimeSelect = useYieldCallback((dateTime) => {
    updateInstantTourForm({ selectedDateTime: dateTime });
    setIsPrimaryBtnDisabled(false);
  });

  const handleTimeSelect = useCallback(
    (e) => {
      const dateTime = e.currentTarget.value;
      yieldHandleTimeSelect(dateTime);
    },
    [yieldHandleTimeSelect],
  );

  const renderCarouselBtn = useCallback(
    (dateISOString: string, isDisabled: boolean) => {
      const formattedDate = dateUtils.parseISODateString(dateISOString, zoneId, 'yyyy-MM-dd');
      const dayOfWeek = dateUtils.parseISODateString(dateISOString, zoneId, 'EEE');
      const monthAndDay = dateUtils.parseISODateString(dateISOString, zoneId, 'MMM dd');
      const isPressed = selectedDate === formattedDate;

      return (
        <S.DateButton
          key={formattedDate}
          disabled={isDisabled}
          isPressed={isPressed}
          aria-pressed={isPressed}
          onClick={handleDateSelect}
          ref={isPressed ? selectedDateBtnRef : null}
          value={formattedDate}
          type="button"
        >
          <S.ButtonText size="lg">{dayOfWeek}</S.ButtonText>
          <S.ButtonText>{monthAndDay}</S.ButtonText>
        </S.DateButton>
      );
    },
    [handleDateSelect, selectedDate, zoneId],
  );

  const renderDisabledCarouselBtn = useCallback(
    (lastAvailableTimeISO: string, daysToAddToLastAvailableDate: number) => {
      const disabledDate: Date = dateUtils.parseISODateString(lastAvailableTimeISO);
      const originalDayOfMonth = disabledDate.getUTCDate();

      disabledDate.setDate(originalDayOfMonth + daysToAddToLastAvailableDate);
      const isIncrementingMonth = disabledDate.getUTCDate() < originalDayOfMonth;

      if (isIncrementingMonth) {
        disabledDate.setUTCMonth(disabledDate.getUTCMonth() + 1);
        disabledDate.setUTCDate(daysToAddToLastAvailableDate);
      }

      return renderCarouselBtn(disabledDate.toISOString(), true);
    },
    [renderCarouselBtn],
  );

  const renderDateCarousel = useCallback(() => {
    if (noToursAvailable) {
      return null;
    }

    const carouselButtons = [];

    for (let idx = carouselIdx; idx < carouselIdx + numBtnsInCarousel; idx++) {
      if (idx >= datesInCarousel.length) {
        const lastCarouselDate = datesInCarousel[datesInCarousel.length - 1];
        const lastAvailableTimeISO = availableToursMap[lastCarouselDate][0];
        const daysToAddToLastAvailableDate = idx - (datesInCarousel.length - 1);
        carouselButtons.push(renderDisabledCarouselBtn(lastAvailableTimeISO, daysToAddToLastAvailableDate));
      } else {
        const carouselDate = datesInCarousel[idx];
        const availableTimeISO = availableToursMap[carouselDate][0];
        carouselButtons.push(renderCarouselBtn(availableTimeISO, false));
      }
    }

    return (
      <S.DateCarouselContainer>
        <VisuallyHidden as="legend">Select date</VisuallyHidden>
        <S.CarouselArrowButton
          aria-label="See previous dates"
          disabled={carouselIdx === 0}
          onClick={handleBackArrowClick}
          type="button"
        >
          <img alt="" height="30px" width="30px" src={circleArrowLeftSmall} />
        </S.CarouselArrowButton>
        {carouselButtons}
        <S.CarouselArrowButton
          aria-label="See more dates"
          disabled={carouselIdx + numBtnsInCarousel >= datesInCarousel.length}
          onClick={handleNextArrowClick}
          type="button"
        >
          <img alt="" height="30px" width="30px" src={circleArrowRightSmall} />
        </S.CarouselArrowButton>
      </S.DateCarouselContainer>
    );
  }, [
    noToursAvailable,
    carouselIdx,
    handleBackArrowClick,
    numBtnsInCarousel,
    datesInCarousel,
    handleNextArrowClick,
    availableToursMap,
    renderDisabledCarouselBtn,
    renderCarouselBtn,
  ]);

  const renderTimeButtons = useCallback(() => {
    if (noToursAvailable || dateOnly || !selectedDate || !availableToursMap[selectedDate]) {
      return null;
    }

    const timeButtons = [];

    availableToursMap[selectedDate].forEach((availableDateTimeISOString) => {
      const isPressed = selectedDateTime === availableDateTimeISOString;
      const time = dateUtils.parseISODateString(availableDateTimeISOString, zoneId, 'h:mm a');

      const TimeButton = (
        <S.TimeButton
          key={availableDateTimeISOString}
          isPressed={isPressed}
          aria-pressed={isPressed}
          onClick={handleTimeSelect}
          value={availableDateTimeISOString}
          type="button"
        >
          <S.ButtonText>{time}</S.ButtonText>
        </S.TimeButton>
      );

      timeButtons.push(TimeButton);
    });

    return (
      <S.TimeButtonsContainer numBtnsInCarousel={numBtnsInCarousel}>
        <VisuallyHidden as="legend">Select time</VisuallyHidden>
        {timeButtons}
      </S.TimeButtonsContainer>
    );
  }, [
    noToursAvailable,
    dateOnly,
    selectedDate,
    availableToursMap,
    numBtnsInCarousel,
    selectedDateTime,
    zoneId,
    handleTimeSelect,
  ]);

  if (isAvailTimesReqLoading) {
    return (
      <div style={{ padding: '10px' }} aria-live="assertive">
        <Text>Loading available tour times...</Text>
      </div>
    );
  }

  return noToursAvailable ? (
    <NoToursAvailableError />
  ) : (
    <>
      <S.Header>Select a tour time</S.Header>
      {dateOnly && selectedTourType === TOUR_TYPES.SELF_GUIDED && (
        <S.Subheader>For self-guided tours, you can visit any time during the day.</S.Subheader>
      )}
      <S.DateTimeForm>
        {renderDateCarousel()}
        {renderTimeButtons()}
      </S.DateTimeForm>
    </>
  );
};

export default DateTimePicker;
