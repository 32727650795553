// @ts-nocheck
/* eslint-enable */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'app/shared/core/Text';
import { useYieldCallback } from '@zillow/yield-callback';

import { TOUR_TYPES } from '../../constants';
import { analyticsEvent } from 'app/client/universal-analytics';
import { gaEvents } from 'app/shared/constants/AnalyticsConstants';

import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';
import locationMarkerIcon from 'images/icons/location-marker.svg';
import inPersonTourIcon from 'images/icons/in-person-tour.svg';
import selfGuideTourIcon from 'images/icons/self-guided-tour.svg';
import liveVideoTourIcon from 'images/icons/live-video-tour.svg';
import caretUp from 'images/icons/ic-caret-up.svg';
import caretDown from 'images/icons/ic-caret-down.svg';

import * as S from './styles';
import { useInstantTourForm } from '../../hooks';
import type { RootReduxState } from 'app/shared/flux/reducers';

const SelectTourTypeStep = ({ setIsPrimaryBtnDisabled = () => {} }) => {
  const dispatch = useDispatch();
  const { updateInstantTourForm } = useInstantTourForm();
  const selectedRadioRef = useRef(null);
  const [showLiveVideoTourInfo, setShowLiveVideoTourInfo] = useState(false);
  const [showSelfGuidedTourInfo, setShowSelfGuidedTourInfo] = useState(false);
  const isMobile = useSelector((state: RootReduxState) => state.app.device.isMobile);
  const currentListing = useSelector((state: RootReduxState) => state.currentListingDetails.currentListing);
  const { tourType: selectedTourType } = useSelector(
    (state: RootReduxState) => state.currentListingDetails.instantTourForm,
  );
  const { address, displayName, details } = currentListing;
  const { street } = address;
  const { inPersonProvider, liveVideoProvider, selfGuidedProvider } = details.instantTourProviders ?? {};

  useEffect(() => {
    if (!selectedTourType) {
      if (inPersonProvider) {
        updateInstantTourForm({ tourType: TOUR_TYPES.IN_PERSON });
      } else if (selfGuidedProvider) {
        updateInstantTourForm({ tourType: TOUR_TYPES.SELF_GUIDED });
      } else if (liveVideoProvider) {
        updateInstantTourForm({ tourType: TOUR_TYPES.LIVE_VIDEO });
      }
    }

    selectedRadioRef?.current?.focus();
    setIsPrimaryBtnDisabled(false);

    // We only want this useEffect to run on initial render:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const yieldHandleTourTypeSelect = useYieldCallback((newTourType) => {
    if (newTourType !== selectedTourType) {
      updateInstantTourForm({
        tourType: newTourType,
        selectedDate: '',
        selectedDateTime: '',
      });
      dispatch(ListingEngineActions.hpTours.clearAvailableToursForListing());
      dispatch(analyticsEvent(gaEvents.HPTOUR_TOUR_TYPE_SELECT, { label: newTourType }));
    }
  });

  const handleTourTypeSelect = useCallback(
    (newTourType: string) => {
      yieldHandleTourTypeSelect(newTourType);
    },
    [yieldHandleTourTypeSelect],
  );

  const handleInPersonTypeSelect = useCallback(() => {
    handleTourTypeSelect(TOUR_TYPES.IN_PERSON);
  }, [handleTourTypeSelect]);

  const handleSelfGuidedTypeSelect = useCallback(() => {
    handleTourTypeSelect(TOUR_TYPES.SELF_GUIDED);
  }, [handleTourTypeSelect]);

  const handleLiveVideoTypeSelect = useCallback(() => {
    handleTourTypeSelect(TOUR_TYPES.LIVE_VIDEO);
  }, [handleTourTypeSelect]);

  const handleShowSelfGuidedTourInfo = useYieldCallback(() => {
    setShowSelfGuidedTourInfo(!showSelfGuidedTourInfo);
  });

  const handleShowLiveVideoTourInfo = useYieldCallback(() => {
    setShowLiveVideoTourInfo(!showLiveVideoTourInfo);
  });

  const getTourIconAndLabel = (tourTypeToLabel = TOUR_TYPES.IN_PERSON, tourTypeIsAvailable = true) => (
    <S.TourIconAndLabel
      onClick={tourTypeIsAvailable ? () => handleTourTypeSelect(tourTypeToLabel) : null}
      isClickable={tourTypeIsAvailable}
    >
      <S.IconAndTitle>
        {tourTypeToLabel === TOUR_TYPES.IN_PERSON && <S.LabelIcon alt="" src={inPersonTourIcon} />}
        {tourTypeToLabel === TOUR_TYPES.SELF_GUIDED && <S.LabelIcon alt="" src={selfGuideTourIcon} />}
        {tourTypeToLabel === TOUR_TYPES.LIVE_VIDEO && <S.LabelIcon alt="" src={liveVideoTourIcon} />}
        <Text size="md">
          {tourTypeToLabel === TOUR_TYPES.IN_PERSON && <>In-person tour</>}
          {tourTypeToLabel === TOUR_TYPES.SELF_GUIDED && <>Self-guided tour</>}
          {tourTypeToLabel === TOUR_TYPES.LIVE_VIDEO && <>Live video tour</>}
        </Text>
      </S.IconAndTitle>
      <Text style={{ maxWidth: isMobile ? '300px' : 'none' }} size="md" htmlTag="p">
        {tourTypeToLabel === TOUR_TYPES.IN_PERSON && <>Tour on site with the property manager guiding you</>}
        {tourTypeToLabel === TOUR_TYPES.SELF_GUIDED && <>Tour on site at your own pace.</>}
        {tourTypeToLabel === TOUR_TYPES.LIVE_VIDEO && <>Video chat from home with the property manager guiding you.</>}
      </Text>
    </S.TourIconAndLabel>
  );

  const getLearnMoreContentBtn = (isLearnMoreContentOpen, tourTypeToRender, onClick) => {
    let ariaLabel = '';
    const skipWhileTabbing = tourTypeToRender !== selectedTourType;
    const isSelfGuided = tourTypeToRender === TOUR_TYPES.SELF_GUIDED;

    if (isLearnMoreContentOpen) {
      ariaLabel = 'Hide information about ';
    } else {
      ariaLabel = 'Learn more about ';
    }
    ariaLabel += isSelfGuided ? 'self-guided tours.' : 'live video tours.';

    return (
      <S.LearnMoreTextBtn
        aria-label={ariaLabel}
        onClick={onClick}
        aria-live="assertive"
        tabIndex={skipWhileTabbing ? -1 : 0}
      >
        <Text size="md">
          {!isLearnMoreContentOpen && 'Learn more'}
          {isLearnMoreContentOpen && 'Hide'}
        </Text>
        <img alt="" style={{ paddingLeft: '5px' }} src={isLearnMoreContentOpen ? caretUp : caretDown} />
      </S.LearnMoreTextBtn>
    );
  };

  const getLearnMoreContent = (isLearnMoreContentOpen, tourTypeToRender, isBelowRadio, onClick) => (
    <>
      <S.LearnMoreContainer isBelowRadio={isBelowRadio}>
        {getLearnMoreContentBtn(isLearnMoreContentOpen, tourTypeToRender, onClick)}
        {isLearnMoreContentOpen && (
          <>
            <S.LearnMoreTopContent>
              <img
                alt=""
                height="50px"
                width="50px"
                src={tourTypeToRender === TOUR_TYPES.SELF_GUIDED ? selfGuideTourIcon : liveVideoTourIcon}
              />
              {tourTypeToRender === TOUR_TYPES.SELF_GUIDED && (
                <S.LearnMoreText>
                  To help renters freely review the property without property manager guiding, many properties are
                  enabling self-guided tour to streamline the touring experience.
                </S.LearnMoreText>
              )}
              {tourTypeToRender === TOUR_TYPES.LIVE_VIDEO && (
                <S.LearnMoreText>
                  This tour lets you visit the property from anywhere using a live video chat with the property manager.
                </S.LearnMoreText>
              )}
            </S.LearnMoreTopContent>
            <S.LearnMoreText style={{ fontWeight: 'bold' }}>How does it work?</S.LearnMoreText>
            <S.LearnMoreText>
              {tourTypeToRender === TOUR_TYPES.SELF_GUIDED && (
                <>
                  Properties enabling self-guided tour often times place a smart lockbox or access key pad to give
                  secure access at the time of the tour without property manager opening the door. You will get
                  instructions on how to get inside each property once you book a tour usually via email.
                </>
              )}
              {tourTypeToRender === TOUR_TYPES.LIVE_VIDEO && (
                <>The property will give you instructions before your video tour, usually by email.</>
              )}
            </S.LearnMoreText>
            <S.LearnMoreText style={{ marginBottom: '10px' }}>
              {tourTypeToRender === TOUR_TYPES.SELF_GUIDED && (
                <>
                  Some properties may require additional proof of identification by requiring you to upload your photo
                  of your ID.
                </>
              )}
              {tourTypeToRender === TOUR_TYPES.LIVE_VIDEO && (
                <>
                  You and the property manager will connect using a video chat app. The property manager will be at the
                  property and can show you what you want to see, real time.
                </>
              )}
            </S.LearnMoreText>
          </>
        )}
      </S.LearnMoreContainer>
    </>
  );

  return (
    <S.SelectTourTypeStepContainer>
      <S.LocationMarkerIcon alt="" src={locationMarkerIcon} />
      <S.DisplayNameAndAddress>
        {displayName === street ? displayName : `${displayName}, ${street}`}
      </S.DisplayNameAndAddress>
      <S.Header>Select type of tour</S.Header>
      <S.RadiosContainer role="radiogroup">
        {inPersonProvider && (
          <>
            <S.TourTypeRadio
              ariaLabel="In-person tour"
              name="TourTypeOption"
              id="InPersonTourType"
              isChecked={selectedTourType === TOUR_TYPES.IN_PERSON}
              onChange={handleInPersonTypeSelect}
              ref={selectedTourType === TOUR_TYPES.IN_PERSON ? selectedRadioRef : null}
              value={TOUR_TYPES.IN_PERSON}
            >
              {getTourIconAndLabel(TOUR_TYPES.IN_PERSON)}
            </S.TourTypeRadio>
          </>
        )}
        {selfGuidedProvider && (
          <>
            <S.TourTypeRadio
              ariaLabel="Self-guided tour"
              name="TourTypeOption"
              id="SelfGuidedTourType"
              isChecked={selectedTourType === TOUR_TYPES.SELF_GUIDED}
              onChange={handleSelfGuidedTypeSelect}
              ref={selectedTourType === TOUR_TYPES.SELF_GUIDED ? selectedRadioRef : null}
              value={TOUR_TYPES.SELF_GUIDED}
            >
              {getTourIconAndLabel(TOUR_TYPES.SELF_GUIDED)}
            </S.TourTypeRadio>
            {getLearnMoreContent(showSelfGuidedTourInfo, TOUR_TYPES.SELF_GUIDED, true, handleShowSelfGuidedTourInfo)}
          </>
        )}
        {liveVideoProvider && (
          <>
            <S.TourTypeRadio
              ariaLabel="Live video tour"
              name="TourTypeOption"
              id="LiveVideoTourType"
              isChecked={selectedTourType === TOUR_TYPES.LIVE_VIDEO}
              onChange={handleLiveVideoTypeSelect}
              ref={selectedTourType === TOUR_TYPES.LIVE_VIDEO ? selectedRadioRef : null}
              value={TOUR_TYPES.LIVE_VIDEO}
            >
              {getTourIconAndLabel(TOUR_TYPES.LIVE_VIDEO)}
            </S.TourTypeRadio>
            {getLearnMoreContent(showLiveVideoTourInfo, TOUR_TYPES.LIVE_VIDEO, true, handleShowLiveVideoTourInfo)}
          </>
        )}
      </S.RadiosContainer>
    </S.SelectTourTypeStepContainer>
  );
};

export default SelectTourTypeStep;
